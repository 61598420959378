import React from 'react';
import './style.scss';
import BackgroundImage from '../images/gamer-girl.jpg';

import gatsbyLogo from '../images/gatsby-icon.png';
import bulmaLogo from '../images/bulma-logo.png';
import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="hero is-fullheight gamerGirlBg">
		<Navbar />
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<div className="media-content">
						<div className="content">
							<h1 className="is-uppercase is-size-1 has-text-white visible-text">
								Welcome to DuoQ
							</h1>
							<p className="subtitle has-text-white is-size-3 visible-text">
								You caught us in our development time. We promise you the best collaborative gaming experience in a few weeks, just give us some time :)
							</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default Header;
